<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      loading: String,
      subtext: String,
    },
    data() {
      return {
        datas1: [],
        datas2: [],
        datas3: [],
        datas4: [],
        xAxisData: [],
        series: [],
        dom: null,
        now: new Date(),
        client: null,
        oneDay: 1000,
        yAxisName:''
      };
    },
      watch: {
        value: {
          handler(val) {
            this.loadEcharts(val);
          },
          deep: true,
          immediate: true,
        },
      },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let self = this;
        this.$nextTick(() => {
          self.xAxisData = val.xaxis;
          self.series = val.yaxis;
          self.yAxisName = val.yunit
          let option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: false,
              type:'scroll',
              right:0,
              top:'1%',
              pageButtonGap:80,
              orient:'vertical',
              textStyle:{
                width:100,
                overflow:'truncate',
                ellipsis:'...',
              },
              tooltip: {
                show: true
              },
              selected:{}
            },
            grid: {
              top: 40,
              left: 50,
              right: 40,
              bottom: 40,
              // containLabel: true
            },
            xAxis: [
              {
                type:'category',
                axisLine: {
                  //坐标轴轴线相关设置。数学上的x轴
                  show: false,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  //坐标轴刻度标签的相关设置
                  color: "#5a5a5a",
                  fontSize: 14,
                  showMaxLabel: true, //强制显示最后一个数据的刻度
                },
                splitLine: {
                  show: false
                },
                axisTick: {
                  show: true,
                },
                data: self.xAxisData,
              },
            ],
            yAxis: [
              {
                name: self.yAxisName,
                nameTextStyle: {
                  color: "#333",
                },
                scale:true,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#eaeaea",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  show: true,
                  fontSize: 14,
                  color: "#5a5a5a",
                  padding: 5,
                  formatter: function (value) {
                    if (value === 0) {
                      return value;
                    }
                    return value;
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            series: [
              {
                type: 'line',
                name: '',
                data: self.series,
                symbol: 'none'
              }
            ]
          };
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          self.dom = echarts.init(self.$refs.dom);
          self.dom.showLoading({
            text: '',
            color: '#1989fa',
            spinnerRadius: 10,
            lineWidth: 2,
            maskColor: 'rgba(255, 255, 255, 1)',
            zlevel: 0,
          })
          self.dom.setOption(option);
          setTimeout(() => {
            self.dom.hideLoading();
          }, 1000);
          on(window, "resize", this.resize);
          this.$parent.changeLoad(this.loading)
        });
      },
      randomData(data) {
        let self = this;
        self.now = new Date(+self.now + self.oneDay);
        return {
          name: self.now.toString(),
          value: [self.now, Math.random() * data],
        };
      }
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: 100%;
  }
</style>
