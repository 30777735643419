<template>
  <div class="single-traffic-capture-detail div-scroll">
    <div class="detail-left">
      <div class="res-video">
        <div class="h5videowrapper h5container">
          <video id="videoPlayer0" class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid vjs-16-9" controls preload="none"></video>
        </div>
      </div>
      <div class="res-video">
        <div class="h5videowrapper h5container">
          <video id="videoPlayer1" class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid vjs-16-9" controls preload="none"></video>
        </div>
      </div>
    </div>
    <div class="detail-right">
      <div class="d-con">
        <div class="d-title">
          <div class="d-title-left">
            <i class="el-icon-s-data"></i>
            <span>挠度曲线</span>
          </div>
          <div class="d-title-right">
            <span>测点</span>
            <el-select size="small" v-model="deflectionId" @change="val => changeSelect(1,val)" placeholder="请选择">
              <el-option
                  v-for="item in deflectionList"
                  :key="item.id"
                  :label="item.subName"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="d-chart" v-loading="loading1">
          <chart-one :value="chartData01" loading="loading1"></chart-one>
        </div>
      </div>
      <div class="d-con">
        <div class="d-title">
          <div class="d-title-left">
            <i class="el-icon-s-data"></i>
            <span>应变曲线</span>
          </div>
          <div class="d-title-right">
            <span>测点</span>
            <el-select size="small" v-model="strainId" @change="val => changeSelect(3,val)" placeholder="请选择">
              <el-option
                  v-for="item in strainList"
                  :key="item.id"
                  :label="item.subName"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="d-chart" v-loading="loading3">
          <chart-one :value="chartData03" loading="loading2"></chart-one>
        </div>
      </div>
      <div class="d-con">
        <div class="d-title">
          <div class="d-title-left">
            <i class="el-icon-s-data"></i>
            <span>振动曲线</span>
          </div>
          <div class="d-title-right">
            <span>测点</span>
            <el-select size="small" v-model="vibrationId" @change="val => changeSelect(2,val)" placeholder="请选择">
              <el-option
                  v-for="item in vibrationList"
                  :key="item.id"
                  :label="item.subName"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="d-chart" v-loading="loading2">
          <chart-one :value="chartData02" loading="loading2"></chart-one>
        </div>
      </div>
      <div class="d-con" v-if="isCable == 1">
        <div class="d-title">
          <div class="d-title-left">
            <i class="el-icon-s-data"></i>
            <span>索振动曲线</span>
          </div>
          <div class="d-title-right">
            <span>测点</span>
            <el-select size="small" v-model="cableForceId" @change="val => changeSelect(4,val)" placeholder="请选择">
              <el-option
                  v-for="item in cableForceList"
                  :key="item.id"
                  :label="item.subName"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="d-chart" v-loading="loading4">
          <chart-one :value="chartData04" loading="loading3"></chart-one>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Configs from "./config/index";
  // import VueAliplayerV2 from "vue-aliplayer-v2";
  import chartOne from "./chart/chart01"
  import videojs from 'video.js'
  // import Video from "../../../components/screen01/vtour/video";

  export default {
    components: {
      // Video,
      // VueAliplayerV2,
      chartOne
    },
    data() {
      let {tableColumns} = Configs(this);
      return {
        tableColumns,
        form: {
          pageSize: 10,
          pageNum: 1,
          currentPage: 1,
          total: 0,
          card: "",
          date: [],
        },
        loading1:true,
        loading2:true,
        loading3:true,
        loading4:true,
        chartData01:{
          xaxis:[],
          yaxis:[],
          yunit:''
        },
        chartData02:{
          xaxis:[],
          yaxis:[],
          yunit:''
        },
        chartData03:{
          xaxis:[],
          yaxis:[],
          yunit:''
        },
        chartData04:{
          xaxis:[],
          yaxis:[],
          yunit:''
        },
        pageLoading: false,
        pageData: [],
        options: {
          height: "100%",
          isLive: true,
          format: "mp4",
          autoplay: true,
          skinLayout: false
        },
        currentVideo: {},
        deflectionList:[],
        vibrationList:[],
        strainList:[],
        cableForceList:[],
        isCable:0,
        deflectionId:'',
        vibrationId:'',
        strainId:'',
        cableForceId:'',
        videoList:[],
        videoPlayer:[]
      };
    },
    created() {
      let baseUrl = localStorage.getItem('baseUrl');
      // console.log(this.$route.params);
      this.currentVideo = this.$BRIDGE_VIDEO_LIST[baseUrl].upStream[0];
      // this.getData()
      // this.getData1()
      // this.getData2()
      this.getDeflectionList()
      this.getVibrationList()
      this.getStrainList()
      this.getIsCable()
      this.getVideoList()
    },
    beforeDestroy() {
      if (this.videoPlayer.length > 0){
        this.videoPlayer.forEach(item => {
          item.dispose()
        })
      }
    },
    methods: {
      changeSelect(type,val){
        this.getData(type,val)
      },
      getVideoList(){
        this.$http.get("/intelligentCapture/getHistoryVideo",{params:{id:this.$route.params.id}}).then((res) => {
          if (res.success) {
            this.videoList = res.data.playback.split(',')
            if (this.videoList && this.videoList.length > 0){
              this.videoList.forEach((item,i) => {
                this.initVideo(i,item)
              })
            }
            // this.isCable = res.data;
            // this.getCableForceList();
          }
        });
      },
      initVideo(i,url) {
        this.videoPlayer[i] = videojs("videoPlayer" + i,{
          bigPlayButton: true, // 是否在暂停时显示大的播放按钮，按钮一般默认在左上角
          // autoplay:false,
          controls:true,
          loop:false,
          muted:true
        }, function (){
          // 回调函数，在播放器创建完成后执行
          // 这里我用来处理获取到动态视频地址时手动触发播放器播放
          this.src({
            src: url,
            type: 'video/mp4'
          })
          // this.play()
        })
      },
      getIsCable(){
        this.$http.get("/total/overview/has/cableForce").then((res) => {
          if (res.success) {
            this.isCable = res.data;
            this.getCableForceList();
          }
        });
      },
      getDeflectionList(){
        this.$http.get('/intelligentCapture/list/deflection').then(res => {
          if (res.success){
            this.deflectionList = res.data
            if (this.deflectionList.length > 0){
              this.deflectionId = this.deflectionList[0].id
              this.getData(1,this.deflectionList[0].id)
            }
          }
        })
      },
      getVibrationList(){
        this.$http.get('/intelligentCapture/list/vibration').then(res => {
          if (res.success){
            this.vibrationList = res.data
            if (this.vibrationList.length > 0){
              this.vibrationId = this.vibrationList[0].id
              this.getData(2,this.vibrationList[0].id)
            }
          }
        })
      },
      getStrainList(){
        this.$http.get('/intelligentCapture/list/strain').then(res => {
          if (res.success){
            this.strainList = res.data
            if (this.strainList.length > 0){
              this.strainId = this.strainList[0].id
              this.getData(3,this.strainList[0].id)
            }
          }
        })
      },
      getCableForceList(){
        this.$http.get('/intelligentCapture/list/cableForce').then(res => {
          if (res.success){
            this.cableForceList = res.data
            if (this.cableForceList.length > 0){
              this.cableForceId = this.cableForceList[0].id
              this.getData(4,this.cableForceList[0].id)
            }
          }
        })
      },
      // 挠度曲线
      getData(type,id) {
        this['loading' + type] = true
        this.$http.post('/intelligentCapture/pointLine',{time:this.$route.query.time,pointId:id}).then(res => {
          if (res.success){
            this['chartData0' + type] = res.data
            this['loading' + type] = false
          }
        })
      },
      changeLoad(load){
        this[load] = false
      },
      handleReady(index) {
        console.log(this.$refs['videoPlayer' + index]);
        this.$refs['videoPlayer' + index].play();
      },
    },
  };
</script>
<style scoped>
  .single-traffic-capture-detail{
    display: block;
  }
  .detail-left{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
  }
  .h5container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .res-video{
    width: 49%;
    height: 444px;
    background: #333;
    margin-bottom: 10px;
  }
  .video-js{
    width: 100%;
    height: 100%;
  }
  .detail-right {
    width: 100%;
    /*height: 100%;*/
    /*overflow-y: auto;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /*flex-direction: column;*/
    padding: 0 20px;
  }
  .detail-right::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }
  .detail-right::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .detail-right::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999;
  }
  .d-con {
    width: 49%;
    height: 400px;
  }

  .d-title {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #1E1E1E;
    border-bottom: 1px solid #E9E9E9;
  }

  .d-title i {
    font-size: 20px;
    color: #2C74E1;
    margin-right: 5px;
  }

  .d-chart {
    width: 100%;
    height: calc(100% - 40px);
  }
  .d-title-right span{
    font-size: 14px;
    margin-right: 5px;
  }
  /deep/ .el-select{
    width: 220px;
  }

</style>
